import React from "react"

import { Swiper, SwiperSlide } from "swiper/react"

import { cn } from "../lib/cn"
import { HiChevronLeft, HiChevronRight } from "react-icons/hi"

const Work = ({ title, note, slides }) => {
  const swiperRef = React.useRef(null)
  const [expandedImage, setExpandedImage] = React.useState(null)
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth)
  const [isBeginning, setIsBeginning] = React.useState(true)
  const [isEnd, setIsEnd] = React.useState(false)

  // Debounce resize event
  React.useEffect(() => {
    let timeoutId = null;
    const handleResize = () => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setWindowWidth(window.innerWidth);
      }, 150);
    };

    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
      if (timeoutId) clearTimeout(timeoutId);
    }
  }, [])

  // Memoize expanded image state changes
  const handleExpandImage = React.useCallback((image) => {
    setExpandedImage(image)
  }, [])

  const isExpandedVideo = expandedImage?.includes(".mp4")

  const slidesPerView = windowWidth > 1024 ? 2 : windowWidth > 768 ? 1 : 0.95
  const showNavigation = slides.length > slidesPerView

  return (
    <>
      <div style={{ height: "100vh" }}>
        <div className="dot flex flex-col w-full px-5 md:px-10 lg:px-20 z-20 pt-40">
          <div className="flex w-full flex-col md:flex-row md:justify-between items-end md:items-center gap-6 md:gap-12">
            <div className="flex w-full flex-col md:flex-row gap-2 md:items-center">
              <h1 className="uppercase text-3xl md:text-5xl leading-thight text-black dark:text-white font-normal">{title}</h1>
              {note && <p className="text-lg uppercase text-left text-black dark:text-white">{note}</p>}
            </div>
            {showNavigation && (
              <div className="flex gap-4 items-center">
                <button
                  className="h-12 w-12 flex items-center justify-center bg-black dark:bg-white text-white dark:text-black rounded-full disabled:opacity-50 transition-all duration-300 hover:scale-110"
                  onClick={() => swiperRef.current.swiper.slidePrev()}
                  disabled={isBeginning}>
                  <HiChevronLeft className="text-2xl" />
                </button>
                <button
                  className="h-12 w-12 flex items-center justify-center bg-black dark:bg-white text-white dark:text-black rounded-full disabled:opacity-50 transition-all duration-300 hover:scale-110"
                  onClick={() => swiperRef.current.swiper.slideNext()}
                  disabled={isEnd}>
                  <HiChevronRight className="text-2xl" />
                </button>
              </div>
            )}
          </div>

          <div className="w-full relative -mx-5 md:-mx-10 lg:-mx-20">
            <div className="pl-5 md:pl-10 lg:pl-20">
              <Swiper
                slidesPerView={slidesPerView}
                spaceBetween={30}
                className="mt-12 !overflow-visible"
                speed={700}
                ref={swiperRef}
                slidesPerGroup={1}
                onSlideChange={(swiper) => {
                  setIsBeginning(swiper.isBeginning)
                  setIsEnd(swiper.isEnd)
                }}>
                {slides.map((card, index) => (
                  <SwiperSlide key={index} className="!overflow-visible !w-auto">
                    <WorkCard {...card} setExpandedImage={handleExpandImage} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>

      {expandedImage && (
        <div className={cn("fixed flex transition-all duration-300 items-center justify-center w-full h-screen z-50 top-0 left-0")}>
          {!isExpandedVideo ? (
            <img
              src={expandedImage}
              alt=""
              loading="lazy"
              className={cn("h-full max-h-[80vh] z-10 w-auto object-cover translate-y-2 duration-300 transition-all", {
                "translate-y-0": expandedImage
              })}
            />
          ) : (
            <video
              src={expandedImage}
              autoPlay
              loop
              controls
              muted
              className={cn("h-full max-h-[80vh] z-10 w-auto object-cover translate-y-2 duration-300 transition-all", {
                "translate-y-0": expandedImage
              })}
            />
          )}
          <div onClick={() => handleExpandImage(null)} className="absolute top-0 right-0 w-full h-full bg-black bg-opacity-30" />
        </div>
      )}
    </>
  )
}

export default Work

// Memoize WorkCard component to prevent unnecessary re-renders
const WorkCard = React.memo(({ title, description, src, imageAlt, imageMock, setExpandedImage }) => {
  const hqImage = React.useMemo(() => src?.replace(/(\.[\w\d_-]+)$/g, "_hq$1"), [src])
  const isVideo = React.useMemo(() => src?.includes(".mp4"), [src])

  return (
    <div className="inline-flex w-[calc(100vw-2.5rem)] md:w-[500px] h-[400px] md:h-[500px] flex-col shrink-0 text-center gap-2 bg-white/5 dark:bg-black/5 backdrop-blur-md p-4 md:p-6 rounded-2xl shadow-lg hover:shadow-2xl transition-all duration-500 ease-out border border-black/5 dark:border-white/5 hover:-translate-y-1 hover:bg-white/10 dark:hover:bg-black/10">
      <div className="flex flex-col h-full">
        <h1 className="text-lg md:text-xl uppercase text-black dark:text-white font-medium tracking-wide transition-colors duration-300">{title}</h1>
        {description && <p className="uppercase text-black/80 dark:text-white/80 mb-4 tracking-wide text-xs transition-colors duration-300">{description}</p>}
        <div className="flex-1 flex items-center justify-center">
          {imageMock === "desktop" && <DesktopMock src={src} setExpandedImage={setExpandedImage} />}
          {imageMock === "phone" && <MobileMock src={src} setExpandedImage={setExpandedImage} />}
          {imageMock === "none" && !isVideo && (
            <img 
              src={src} 
              alt="" 
              loading="lazy"
              className="max-h-72 w-auto mx-auto rounded-lg shadow-md" 
              onClick={() => setExpandedImage(hqImage)} 
            />
          )}
          {imageMock === "none" && isVideo && (
            <div className="h-72 w-fit mx-auto rounded-lg shadow-md overflow-hidden" onClick={() => setExpandedImage(src)}>
              <video 
                src={src} 
                autoPlay 
                loop 
                muted 
                playsInline
                className="w-full h-full object-cover" 
              />
            </div>
          )}
        </div>
        {imageAlt && <p className="uppercase text-black/70 dark:text-white/70 mt-auto tracking-wide text-xs">{imageAlt}</p>}
      </div>
    </div>
  )
})

const DesktopMock = ({ src, setExpandedImage }) => {
  const isVideo = src?.includes(".mp4")
  const hqImage = src?.replace(/(\.[\w\d_-]+)$/g, "_hq$1")

  return (
    <div className="relative flex w-full h-52 justify-center group" onClick={() => setExpandedImage(isVideo ? src : hqImage)}>
      <img src="/images/mocks/macbook.svg" alt="" className="h-52 absolute z-20 drop-shadow-lg" />
      {!isVideo && (
        <img 
          src={src} 
          alt="" 
          className="h-[150px] absolute z-10 rounded-sm transition-transform duration-300 group-hover:scale-[1.02]" 
        />
      )}
      {isVideo && (
        <div className="h-[150px] top-1 w-[235px] overflow-hidden absolute z-10 rounded-lg">
          <video 
            src={src} 
            autoPlay 
            loop 
            muted 
            className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-[1.02]" 
          />
        </div>
      )}
    </div>
  )
}

const MobileMock = ({ src, setExpandedImage }) => {
  const isVideo = src?.includes(".mp4")
  const hqImage = src?.replace(/(\.[\w\d_-]+)$/g, "_hq$1")

  return (
    <div className="relative flex w-full h-52 justify-center group" onClick={() => setExpandedImage(isVideo ? src : hqImage)}>
      <img src="/images/mocks/iphone.svg" alt="" className="h-52 absolute z-20 drop-shadow-lg" />
      {isVideo && (
        <video 
          src={src} 
          autoPlay 
          loop 
          muted 
          className="w-[6rem] h-[13rem] object-cover rounded-xl transition-transform duration-300 group-hover:scale-[1.02]" 
        />
      )}
      {!isVideo && (
        <div alt="" className="h-[12.5rem] top-1 w-24 absolute z-10">
          <img 
            src={src} 
            alt="" 
            className="w-full h-full object-cover rounded-xl transition-transform duration-300 group-hover:scale-[1.02]" 
          />
        </div>
      )}
    </div>
  )
}
