const WORKS = [
  {
    title: "Cannahub",
    note: "",
    slides: [
      {
        title: "Introducción",
        text: "Cannahub es una plataforma para gestión y acceso seguro a cannabis para pacientes médicos, facilitando su adquisición de manera fácil y segura a través de clubes de cultivo.",
        src: "/images/works/cannahub/1.png",
        imageMock: "desktop",
        imageAlt: "Login/Registro"
      },
      {
        title: "Proyecto",
        text: "Enfocado en una interfaz comprensible para usuarios, con énfasis en seguridad y accesibilidad.",
        src: "/images/works/cannahub/2.png",
        imageMock: "phone",
        imageAlt: "Dispensario"
      },
      {
        title: "Reprocann",
        text: "Cannahub incluye asesoramiento para obtener el permiso de Reprocann, permitiendo cultivar y portar cannabis medicinal.",
        src: "/images/works/cannahub/3.png",
        imageMock: "desktop",
        imageAlt: "Paso 3 - Adjuntar Documentos"
      },
      {
        title: "Design System",
        text: "Implementamos un sistema de diseño con base en Tailwind para asegurar coherencia y uniformidad en la plataforma.",
        src: "/images/works/cannahub/4.png",
        imageMock: "phone",
        imageAlt: "Design System aplicado"
      }
    ]
  },
  {
    title: "Elemental",
    note: "",
    slides: [
      {
        title: "Publicidad Animada",
        text: "Creé publicidades animadas expuestas en puntos estratégicos de La Plata, como Av. 7 y Baxar.",
        src: "/videos/works/elemental/1.mp4",
        imageMock: "desktop",
        imageAlt: "Publicidad expuesta en la vía pública"
      },
      {
        title: "Redes Sociales",
        text: "Desarrollamos contenido diverso para redes sociales, incluyendo publicidad paga, historias, reels y posts.",
        src: "/images/works/elemental/2.png",
        imageMock: "phone",
        imageAlt: "Feed en Instagram"
      },
      {
        title: "Brochure",
        text: "Diseñé el brochure de la marca, ofreciendo información sobre el proceso, beneficios y opciones de financiación.",
        src: "/images/works/elemental/1.png",
        imageMock: "none",
        imageAlt: "Tapa y contratapa del brochure"
      },
      {
        title: "Página web",
        text: "También participé en el diseño de su página web, facilitando el acceso a la información",
        src: "/images/works/elemental/3.png",
        imageMock: "desktop",
        imageAlt: "Vista de la página web"
      }
    ]
  },
  {
    title: "Procter & Gamble",
    note: "",
    slides: [
      {
        title: "P&G",
        text: "Desarrollé variadas campañas publicitarias para redes sociales, cartelería urbana, mailing, televisión y comunicación interna.",
        src: "/images/works/p&g/1.png",
        imageMock: "phone",
        imageAlt: "Sorteo en redes sociales de p&g"
      },
      {
        title: "Oral-B",
        text: "Generé contenido principalmente para redes sociales y mailing para Oral-B.",
        src: "/images/works/p&g/2.png",
        imageMock: "phone",
        imageAlt: "Sorteo en redes sociales de oral-b"
      },
      {
        title: "Magistral",
        text: "Para Magistral, creé contenido para televisión, redes sociales y publicidad alternativa.",
        src: "/images/works/p&g/3.png",
        imageMock: "phone",
        imageAlt: "Campaña “probalos” de magistral"
      },
      {
        title: "Pampers",
        text: "Trabajar con Pampers fue gratificante gracias a su precisa segmentación de mercado, generando contenido atractivo.",
        src: "/images/works/p&g/4.png",
        imageMock: "phone",
        imageAlt: "Campaña “probalos” de pampers"
      },
      {
        title: "Vick Vaporub",
        text: "Realicé diversas animaciones y publicaciones en redes para Vick Vaporub, destacándome por ser un proyecto íntegramente mío.",
        src: "/images/works/p&g/5.png",
        imageMock: "phone",
        imageAlt: "Publicidad de redes sociales de Vick"
      },
      {
        title: "Head & Shoulders",
        text: "Aunque tuve menos interacción con Head & Shoulders, el contenido creado en equipo para cartelería urbana fue exitoso.",
        src: "/images/works/p&g/6.png",
        imageMock: "phone",
        imageAlt: "Campaña “probalos” de head & shoulders"
      }
    ]
  },
  {
    title: "Freelance",
    note: "",
    slides: [
      {
        title: "Nestlé Maní",
        text: "Realicé un proyecto independiente para Nestlé enfocado en comunicación interna.",
        src: "/videos/works/freelance/1.mp4",
        imageMock: "phone",
        imageAlt: "Realizado en After Effects"
      },
      {
        title: "Charly y la Fábrica de Chocolate",
        src: "/videos/works/freelance/2.mp4",
        imageMock: "none",
        imageAlt: "UADE 2023"
      },

      {
        title: "Matera Automática",
        text: "Desarrollé una máquina de mate automática como proyecto universitario para 'Diseño de Interacción 3' en la UADE.",
        src: "/images/works/freelance/5.png",
        imageMock: "none",
        imageAlt: "UADE 2023"
      },
      {
        title: "The Mate",
        text: "Elaboré mockups para un emprendimiento de mates, visualizando el diseño final del producto.",
        src: "/images/works/freelance/6.png",
        imageMock: "none",
        imageAlt: "Realizado en Blender"
      }
    ]
  }
]

export default WORKS
