import React from "react"
import { MdOutlineAlternateEmail } from "react-icons/md"
import { FaLinkedinIn } from "react-icons/fa"

const AboutMe = (props) => {
  return (
    <>
      <AboutMeTitle />
      <AboutMeEducation />
      <AboutMeExperience />
      <AboutMeContact />
    </>
  )
}

export default AboutMe

const AboutMeTitle = () => {
  return (
    <div className="relative" style={{ height: "30vh" }}>
      <div className="dot px-5 md:px-10 lg:px-20 w-full pt-40">
        <div className="flex w-full flex-col items-end">
          <h1 className="uppercase text-[50px] md:text-[80px] leading-thight text-black dark:text-white font-normal">About</h1>
          <h1 className="uppercase text-[50px] md:text-[80px] leading-tight mt-2 text-black dark:text-white font-normal">Me</h1>
        </div>
      </div>
    </div>
  )
}

const AboutMeEducation = () => {
  return (
    <div style={{ height: "50vh" }}>
      <div className="dot px-5 md:px-10 lg:px-20 w-full pt-20">
        <div className="flex flex-col gap-8">
          <h4 className="uppercase text-2xl text-black dark:text-white">Education</h4>
          <div className="">
            <h1 className="uppercase text-xl text-black dark:text-white">Diseño Multimedial y de Interacción</h1>
            <h2 className="text-black dark:text-white">2019 - Actualidad</h2>
            <h2 className="text-black dark:text-white">Universidad Argentina de la Empresa</h2>
          </div>

          <div className="">
            <h1 className="uppercase text-xl text-black dark:text-white">Diseño UX/UI</h1>
            <h2 className="text-black dark:text-white">2020</h2>
            <h2 className="text-black dark:text-white">Coderhouse</h2>
          </div>

          <div className="">
            <h1 className="uppercase text-xl text-black dark:text-white">After Effects</h1>
            <h2 className="text-black dark:text-white">2021</h2>
            <h2 className="text-black dark:text-white">Coderhouse</h2>
          </div>
        </div>
      </div>
    </div>
  )
}

const AboutMeExperience = () => {
  return (
    <div style={{ height: "50vh" }}>
      <div className="dot px-5 md:px-10 lg:px-20 w-full pt-20">
        <div className="flex items-end flex-col gap-8 text-right">
          <h4 className="uppercase text-2xl text-black dark:text-white">Professional Experience</h4>
          <div className="">
            <h1 className="uppercase text-xl text-black dark:text-white">Paqtech S.A.</h1>
            <h2 className="text-black dark:text-white">2018 - 2019</h2>
            <h2 className="text-black dark:text-white">Web developement - Ecommerce (VTex)</h2>
          </div>

          <div className="">
            <h1 className="uppercase text-xl text-black dark:text-white">Salve Agency</h1>
            <h2 className="text-black dark:text-white">2020 - 2021</h2>
            <h2 className="text-black dark:text-white">UX Design - Web developement</h2>
          </div>

          <div className="">
            <h1 className="uppercase text-xl text-black dark:text-white">Publicis Groupe</h1>
            <h2 className="text-black dark:text-white">2021 - 2022</h2>
            <h2 className="text-black dark:text-white">Design Trainee (Procter&Gamble)</h2>
          </div>

          <div className="">
            <h1 className="uppercase text-xl text-black dark:text-white">Cannahub</h1>
            <h2 className="text-black dark:text-white">2023 - Current</h2>
            <h2 className="text-black dark:text-white">UI Design - UX Design</h2>
          </div>
        </div>
      </div>
    </div>
  )
}

const AboutMeContact = () => {
  return (
    <div className="relative" style={{ height: "100vh" }}>
      <div className="dot px-5 md:px-10 lg:px-20 h-full !flex justify-center items-center w-full">
        <div className="flex flex-col w-full z-20 justify-center gap-8 md:gap-12">
          <div className="flex flex-col lg:flex-row justify-center items-center gap-6 md:gap-12">
            <div className="flex items-center uppercase text-white gap-3 md:gap-4 whitespace-normal lg:whitespace-nowrap">
              <MdOutlineAlternateEmail className="text-black dark:text-white text-2xl md:text-3xl shrink-0" />
              <a 
                target="_blank" 
                rel="noreferrer" 
                className="text-black dark:text-white text-sm sm:text-base md:text-lg lg:text-xl hover:opacity-70 transition-opacity duration-300" 
                href="mailto:gian.nicolicchia@gmail.com"
              >
                Gian.Nicolicchia@gmail.com
              </a>
            </div>
            <div className="flex items-center uppercase text-white gap-3 md:gap-4 whitespace-nowrap">
              <FaLinkedinIn className="text-black dark:text-white text-2xl md:text-3xl shrink-0" />
              <a 
                target="_blank" 
                rel="noreferrer" 
                className="text-black dark:text-white text-sm sm:text-base md:text-lg lg:text-xl hover:opacity-70 transition-opacity duration-300" 
                href="https://linkedin.com/in/gian-nicolicchia"
              >
                /in/gian-nicolicchia
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
