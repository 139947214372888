import React from "react"
import Button from "./Button"
import { Sections } from "../config/sections"
import DarkModeSwitch from "./DarkModeSwitch"

export const NAV_ITEMS = [
  {
    name: "Home",
    id: Sections.HOME,
    offset: 0,
    percent: 0
  },
  {
    name: "My Work",
    id: Sections.MY_WORK,
    offset: window.innerHeight * 1.1,
    percent: 0.30
  },
  {
    name: "About Me",
    id: Sections.ABOUT_ME,
    offset: window.innerHeight * 5.4,
    percent: 0.75
  },
  {
    name: "Contact",
    id: Sections.CONTACT,
    offset: 10000,
    percent: 0.95
  }
]

const Header = ({ currentSection, setCurrentSection }) => {
  const handleScroll = (id) => {
    const scrollDiv = document.getElementById("scroll-area")
    const scrollOffset = NAV_ITEMS.find((item) => item.id === id).offset
    scrollDiv.scrollTo({
      top: scrollOffset,
      behavior: "smooth"
    })
  }

  const handleLogoClick = () => {
    handleScroll(Sections.HOME)
    setCurrentSection(Sections.HOME)
  }

  return (
    <div className="fixed z-50 top-0 right-0 w-full h-20 bg-white/10 dark:bg-black/10 backdrop-blur-md">
      <div className="h-full px-5 md:px-10 lg:px-20 items-center flex justify-between">
        <img 
          src="/logo.svg" 
          className="h-11 w-auto dark:invert transition-all duration-300 hover:-translate-y-1 cursor-pointer" 
          onClick={handleLogoClick}
        />
        <div className="flex gap-4 items-center">
          <div className="transition-transform duration-300 hover:-translate-y-1">
            <DarkModeSwitch />
          </div>
          <div className="md:flex items-center gap-4 hidden">
            {NAV_ITEMS.map((item) => (
              <div key={item.id} className="transition-transform duration-300 hover:-translate-y-1">
                <Button onClick={() => handleScroll(item.id)} upperCase active={currentSection === item.id}>
                  {item.name}
                </Button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
