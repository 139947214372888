import React from "react"

const MyWork = (props) => {
  return (
    <div style={{ height: "40vh" }}>
      <div className="dot !flex h-full justify-end flex-col px-5 md:px-10 lg:px-20 md:pt-20">
        <h1 className="uppercase text-[50px] md:text-[80px] leading-tight text-black dark:text-white font-normal">My</h1>
        <h1 className="uppercase text-[50px] md:text-[80px] leading-tight mt-0 text-black dark:text-white font-normal">Work</h1>
      </div>
    </div>
  )
}

export default MyWork
