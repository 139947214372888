import React, { forwardRef } from "react"
import Header, { NAV_ITEMS } from "./components/Header"
import Home from "./screens/Home"
import MyWork from "./screens/MyWork"
import Work from "./screens/Work"
import AboutMe from "./screens/AboutMe"
import { cn } from "./lib/cn"
import { Sections } from "./config/sections"
import WORKS from "./lib/works"

export const SectionsTrigger = {
  HOME: NAV_ITEMS[0].percent,
  MY_WORK: NAV_ITEMS[1].percent,
  ABOUT_ME: NAV_ITEMS[2].percent,
  CONTACT: NAV_ITEMS[3].percent
}

const Overlay = forwardRef(({ caption, scroll }, ref) => {
  const [currentSection, setCurrentSection] = React.useState(Sections.HOME)
  const [scrollPercent, setScrollPercent] = React.useState(0)
  const [smoothScrollPercent, setSmoothScrollPercent] = React.useState(0)

  // Add smooth scrolling animation
  React.useEffect(() => {
    const animate = () => {
      setSmoothScrollPercent(prev => {
        const diff = scrollPercent - prev
        return prev + diff * 0.15 // Increased from 0.05 to 0.15 for faster updates
      })
      requestAnimationFrame(animate)
    }
    const animationFrame = requestAnimationFrame(animate)
    return () => cancelAnimationFrame(animationFrame)
  }, [scrollPercent])

  React.useEffect(() => {
    // Define section ranges with clear boundaries
    if (smoothScrollPercent <= 0.09) {
      setCurrentSection(Sections.HOME)
    } else if (smoothScrollPercent > 0.09 && smoothScrollPercent <= 0.35) {
      setCurrentSection(Sections.MY_WORK)
    } else if (smoothScrollPercent > 0.35 && smoothScrollPercent <= 0.80) {
      setCurrentSection(Sections.ABOUT_ME)
    } else {
      setCurrentSection(Sections.CONTACT)
    }
  }, [smoothScrollPercent])

  const onSectionChange = (section) => {
    const scrollDiv = document.getElementById("scroll-area")
    if (section === Sections.HOME) {
      scroll.current = 0
      scrollDiv.scrollTo({ top: 0, behavior: "smooth" })
    } else {
      // Add scroll handling for other sections
      const targetPercent = SectionsTrigger[section]
      const scrollHeight = scrollDiv.scrollHeight
      const viewportHeight = window.innerHeight
      const maxScroll = scrollHeight - viewportHeight
      const targetScroll = maxScroll * targetPercent
      scrollDiv.scrollTo({ top: targetScroll, behavior: "smooth" })
    }
    setCurrentSection(section)
  }

  return (
    <div
      ref={ref}
      onScroll={(e) => {
        // Calculate scroll percentage based on actual content height
        const scrollHeight = e.target.scrollHeight
        const viewportHeight = window.innerHeight
        const maxScroll = scrollHeight - viewportHeight
        const currentScroll = e.target.scrollTop
        
        // Ensure we don't divide by zero
        const rawPercent = maxScroll > 0 ? currentScroll / maxScroll : 0
        scroll.current = rawPercent
        setScrollPercent(rawPercent)
      }}
      className="scroll z-20"
      id="scroll-area">
      <Header currentSection={currentSection} setCurrentSection={onSectionChange} />
      <Home scrollPercent={smoothScrollPercent} />
      <MyWork />
      {WORKS.map((work, index) => (
        <Work key={index} title={work.title} note={work.note} slides={work.slides} />
      ))}
      <AboutMe />

      <span className="caption hidden" ref={caption}>
        {smoothScrollPercent.toFixed(3)}
      </span>
      <div className="w-full h-20 bg-white/10 dark:bg-black/10 backdrop-blur-xl flex flex-col md:flex-row items-center justify-center">
        <div className="span">
          Made with <span className="mx-1">❤</span> by
        </div>
        <span className="md:ml-2">Gianluca Nicolicchia & Joaquin Santarcangelo</span>
      </div>
    </div>
  )
})

export default Overlay
