import React, { useState } from "react"
import { useProgress } from "@react-three/drei"
import { cn } from "../lib/cn"

const LoadingScreen = () => {
  const [loading, setLoading] = useState(true)
  const { progress } = useProgress()

  React.useEffect(() => {
    if (progress === 100) {
      setTimeout(() => {
        setLoading(false)
      }, 2000)
    }
  }, [progress])

  return (
    <div
      className={cn(
        "fixed flex items-center justify-center top-0 left-0 transition-all duration-1000 w-full h-screen bg-white/30 backdrop-blur-xl z-50",
        {
          "opacity-0 pointer-events-none": !loading
        }
      )}>
      <div className="absolute animate-float">
        <img 
          src="/logo.svg" 
          alt="Gian's logo" 
          className="w-auto h-20 dark:invert transition-[filter,transform] duration-300 hover:scale-110" 
        />
      </div>
      <div style={{ width: "300px" }} className="relative mt-40">
        <div className="h-3 bg-black/10 dark:bg-white/10 rounded-full overflow-hidden backdrop-blur-sm">
          <div 
            style={{ width: `${progress}%` }} 
            className="h-full bg-black dark:bg-white transition-all duration-1000 ease-in-out" 
          />
        </div>
        <div className="text-black dark:text-white text-center mt-4 font-normal tracking-wider transition-opacity duration-500">
          {progress === 100 ? "Loading..." : `${Math.floor(progress)}%`}
        </div>
      </div>
    </div>
  )
}

export default LoadingScreen
