import React from "react"

const Home = (props) => {
  const firstName = "Gianluca"
  const lastName = "Nicolicchia"
  
  const getRandomAnimation = (index) => {
    const animations = [
      "hover:-translate-y-1",
      "hover:rotate-12",
      "hover:-rotate-12",
      "hover:scale-110",
      "hover:scale-90",
      "hover:skew-x-12",
      "hover:-skew-x-12",
      "hover:translate-y-1",
      "hover:translate-x-1",
      "hover:-translate-x-1",
    ]
    return animations[index % animations.length]
  }

  const getRandomColor = (index) => {
    const colors = [
      "hover:text-blue-500",
      "hover:text-red-500",
      "hover:text-green-500",
      "hover:text-purple-500",
      "hover:text-pink-500",
      "hover:text-yellow-500",
      "hover:text-indigo-500",
      "hover:text-orange-500",
      "hover:text-teal-500",
      "hover:text-cyan-500",
    ]
    return colors[index % colors.length]
  }

  return (
    <div style={{ height: "100vh" }} className="flex items-center">
      <div className="dot px-5 md:px-10 lg:px-20">
        <h1 className="uppercase text-5xl sm:text-6xl md:text-[80px] lg:text-[120px] leading-[0.95] text-black dark:text-white font-bold tracking-tighter transition-colors duration-300">
          <span className="inline-flex">
            {firstName.split('').map((letter, index) => (
              <span 
                key={index}
                className={`transition-all duration-300 ${getRandomAnimation(index)} ${getRandomColor(index)}`}
              >
                {letter}
              </span>
            ))}
          </span>
        </h1>
        <h1 className="uppercase text-5xl sm:text-6xl md:text-[80px] lg:text-[120px] leading-[0.95] text-black dark:text-white font-bold tracking-tighter transition-colors duration-300">
          <span className="inline-flex">
            {lastName.split('').map((letter, index) => (
              <span 
                key={index}
                className={`transition-all duration-300 ${getRandomAnimation(index + firstName.length)} ${getRandomColor(index + firstName.length)}`}
              >
                {letter}
              </span>
            ))}
          </span>
        </h1>
        <div className="relative flex w-fit flex-col items-end mt-2">
          <div className="flex items-center gap-4 sm:gap-6 md:gap-8 group">
            <div className="flex flex-col">
              <h2 className="uppercase text-xl sm:text-2xl md:text-3xl lg:text-4xl leading-[1.1] text-black dark:text-white font-normal tracking-tight transition-all duration-300 group-hover:-translate-x-2">
                Multimedia
              </h2>
              <h2 className="uppercase text-xl sm:text-2xl md:text-3xl lg:text-4xl leading-[1.1] text-black dark:text-white font-normal tracking-tight transition-all duration-300 group-hover:translate-x-2">
                Design
              </h2>
            </div>
            <svg 
              className="dark:invert-0 invert transition-all duration-300 h-[calc(2.2*theme(fontSize.xl))] sm:h-[calc(2.2*theme(fontSize.2xl))] md:h-[calc(2.2*theme(fontSize.3xl))] lg:h-[calc(2.2*theme(fontSize.4xl))] w-auto group-hover:translate-y-2" 
              viewBox="0 0 30 94" 
              fill="none" 
              xmlns="http://www.w3.org/2000/svg"
            >
              <g style={{ mixBlendMode: "difference" }}>
                <path d="M17 2C17 0.89543 16.1046 -4.82823e-08 15 0C13.8954 4.82823e-08 13 0.895431 13 2L17 2ZM13.5858 93.4142C14.3668 94.1953 15.6332 94.1953 16.4142 93.4142L29.1421 80.6863C29.9232 79.9052 29.9232 78.6389 29.1421 77.8579C28.3611 77.0768 27.0948 77.0768 26.3137 77.8579L15 89.1716L3.6863 77.8579C2.90525 77.0768 1.63892 77.0768 0.857868 77.8579C0.076819 78.6389 0.076819 79.9052 0.857868 80.6863L13.5858 93.4142ZM13 2L13 92L17 92L17 2L13 2Z" fill="#FAFAFA"/>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
